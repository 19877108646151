'use client'

import React, { useState, useRef, useEffect } from 'react';
import { FaClipboard, FaCheck, FaSpinner } from 'react-icons/fa'; // 添加这行导入图标
const DEFAULT_ORIGINAL_TEXT = "Behold! The masterpiece of my blood, sweat, and tears! 📝💪";
const DEFAULT_HIDDEN_MESSAGE = "Pssst... This is top-secret! 🤫 Copyright by John Doe. No stealing, or else! 🚫🕵️‍♂️";

export default function EncryptForm() {
  const [originalText, setOriginalText] = useState(DEFAULT_ORIGINAL_TEXT);
  const [hiddenMessage, setHiddenMessage] = useState(DEFAULT_HIDDEN_MESSAGE);
  const [password, setPassword] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const hiddenMessageRef = useRef<HTMLTextAreaElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch('/api/auth');
  }, []);

  const handleEncrypt = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/codec', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'encrypt', originalText, hiddenMessage, password }),
      });
      const data = await response.json();
      setEncryptedText(data.encryptedText);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(encryptedText).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // 2秒后重置复制状态
    });
  };

  useEffect(() => {
    if (hiddenMessageRef.current) {
      hiddenMessageRef.current.style.height = 'auto';
      hiddenMessageRef.current.style.height = `${Math.min(hiddenMessageRef.current.scrollHeight, 64)}px`;
    }
  }, [hiddenMessage]);

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Hide Information</h2>

      <div className="mb-4 flex-shrink-0">
        <label className="block mb-2 text-lg">Original Text:</label>
        <textarea
          className="w-full p-3 border rounded mb-2 text-lg"
          placeholder="Enter original text"
          value={originalText}
          onChange={(e) => setOriginalText(e.target.value)}
          style={{ height: '15vh', resize: 'none' }}
        />
      </div>

      <div className="mb-4 flex-shrink-0">
        <label className="block mb-2 text-lg">Hidden Message:</label>
        <textarea
          ref={hiddenMessageRef}
          className="w-full p-3 border rounded mb-2 text-lg"
          placeholder="Enter hidden message"
          value={hiddenMessage}
          onChange={(e) => setHiddenMessage(e.target.value)}
          style={{ maxHeight: '64px', resize: 'none' }}
        />
      </div>

      <div className="mb-4 flex-shrink-0">
        <label className="block mb-2 text-lg">Password (Optional):</label>
        <input
          type="password"
          className="w-full p-3 border rounded mb-2 text-lg"
          placeholder="Enter password (optional)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button
        className={`bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded text-lg mb-4 flex-shrink-0 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleEncrypt}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <FaSpinner className="animate-spin mr-2" />
            Encrypting...
          </>
        ) : (
          'Encrypt'
        )}
      </button>

      <div className="flex-grow flex flex-col min-h-0">
        <label className="block mb-2 text-lg">Encrypted Text:</label>
        <div className="relative flex-grow flex flex-col">
          <textarea
            className="w-full flex-grow p-3 border rounded mb-2 text-lg"
            value={encryptedText}
            readOnly
            style={{ resize: 'none', minHeight: '100px' }}
          />
          <button
            className={`mt-2 self-end px-4 py-2 rounded flex items-center ${isCopied ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } transition-colors duration-300`}
            onClick={handleCopyToClipboard}
          >
            {isCopied ? (
              <>
                <FaCheck className="mr-2" />
                Copied
              </>
            ) : (
              <>
                <FaClipboard className="mr-2" />
                Copy to clipboard
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}