'use client'

import { useState } from 'react'
import { FaSpinner } from 'react-icons/fa'; // 添加这行导入加载图标
import ResultDisplay from './ResultDisplay'

export default function DecryptForm() {
  const [encryptedText, setEncryptedText] = useState('')
  const [password, setPassword] = useState('')
  const [result, setResult] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const handleDecrypt = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/codec', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'decrypt', encryptedText, password }),
      });
      const data = await response.json();
      setResult(data.decryptedMessage);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold mb-4 text-green-800">Reveal Hidden Information</h2>

      <div className="mb-4 flex-shrink-0">
        <label className="block mb-2 text-lg">Encrypted Text:</label>
        <textarea
          className="w-full p-3 border rounded mb-2 text-lg"
          placeholder="Enter encrypted text"
          value={encryptedText}
          onChange={(e) => setEncryptedText(e.target.value)}
          style={{ height: '28vh', resize: 'none' }}
        />
      </div>

      <div className="mb-4 flex-shrink-0">
        <label className="block mb-2 text-lg">Password (Optional):</label>
        <input
          type="password"
          className="w-full p-3 border rounded mb-2 text-lg"
          placeholder="Enter password (optional)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button
        className={`bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded text-lg mb-4 flex-shrink-0 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleDecrypt}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <FaSpinner className="animate-spin mr-2" />
            Decrypting...
          </>
        ) : (
          'Decrypt'
        )}
      </button>

      {result && <ResultDisplay result={result} />}
    </div>
  )
}